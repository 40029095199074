<template>
  <div>
    <div class="vs-row hero">
      <div class="hero-text self-center mx-20">
        <p class="text-5xl font-bold pl-4">Manage Members</p>
        <p class="px-4 text-lg">Lorem ipsum, dolor sit amet, consectetur adipisicing elit, sed</p>
        <vs-breadcrumb :items="items" separator="chevron_right"></vs-breadcrumb>
      </div>
    </div>
    <div class="mx-10">
      <div class="vs-row flex border mt-3 rounded p-2 mb-12 mt-7">
        <div class="vs-col lg:w-1/6 sm:w-1/5 w-full text-center">
          <vs-avatar class="self-center" size="90px" :src="member.child_photo"/>
          <p class="text-base">{{member.child_name}}</p>
          <p class="text-sm text-gray-400">{{member.child_age}} Years Old</p>
        </div>
        <div class="vs-col sm:border-l-2 px-5 py-2 lg:w-5/6 sm:w-4/5 w-full self-center">
          <p class="font-medium">Kid Info</p>
          <p class="text-gray-400 lg:w-1/3 sm:w-2/3">Lorem ipsum, dolor sit amet consectetur adipisicing elit.</p>
          <div class="vs-col flex divide-x divide-gray-300 text-sm pt-3">
            <span @click="editMember()" class="cursor-pointer pr-2 text-blue flex">
              <vs-icon size="15px" class="self-center" color="#00DCDC" icon="edit"></vs-icon>
              <span class="self-center">Edit</span>
            </span>
            <span @click="deleteMember()" class="cursor-pointer px-2 text-red-600 flex">
              <vs-icon size="15px" class="self-center" icon="delete"></vs-icon>
              <span class="self-center">Remove</span>
            </span>
          </div>
        </div>
      </div>
      <div class="vs-row w-full">
        <p class="font-semibold mb-3">Kid Interests</p>
        <div class="vs-col xl:w-1/4 lg:w-1/3 md:w-1/2 sm:w-full w-full p-2" v-for="(item, index) in interests" :key="item.index" :offset="index > 0 ? 2 : 0">
          <el-card :body-style="{ padding: '0px' }">
            <div @click="interestDetail(item)" class="cursor-pointer cardBody">
              <span class="topPicksText">{{item.name}}</span>
              <img :src="item.photo_name" class="image">
            </div>
          </el-card>
        </div>
      </div>
    </div>
    <vs-prompt
      @close="closeModal"
      :active.sync="editMemberModal"
      title="Edit Member"
      :buttons-hidden="true">
      <VuePerfectScrollbar :is="'div'" class="scroll-area p-4" :settings="settings" >
        <form class="w-full">
          <div class="text-center">
            <img class="ml-auto mr-auto rounded-full w-20 h-20" :src="image ? image : userChild.child_photo" />
            <div class="image-upload absolute text-center">
              <label for="file-input">
                <vs-icon class="camera-icon cursor-pointer p-1" icon="photo_camera" size="small" color="#fff"></vs-icon>
              </label>
              <input id="file-input" type="file" @change="changeImage" ref="fileInput" accept="image/*"/>
            </div>
          </div>
          <div class="mb-6">
            <div class="mb-2">
              <label class="mb-6 blue">First Name</label>
            </div>
            <div class="w-full">
              <vs-input name="first name" placeholder="First Name" v-model="userChild.firstName"/>
              <span class="text-danger text-sm" v-show="errors.has('first name')">{{ errors.first('first name') }}</span>
            </div>
          </div>
          <div class="mb-6">
            <div class="mb-2">
              <label class="mb-6 blue">Last Name</label>
            </div>
            <div>
              <vs-input name="last name" placeholder="Last Name" v-model="userChild.lastName"/>
              <span class="text-danger text-sm" v-show="errors.has('last name')">{{ errors.first('last name') }}</span>
            </div>
          </div>
          <div class="mb-6">
            <div class="mb-2">
              <label class="mb-6 blue">Date Of Birth</label>
            </div>
            <div>
              <datepicker name="date of birth" placeholder="Select Date" v-model="userChild.child_dob"></datepicker>
              <span class="text-danger text-sm" v-show="errors.has('date of birth')">{{ errors.first('date of birth') }}</span>
            </div>
          </div>
        </form>
        <vs-button @click="updateMember()" class="w-full">Save</vs-button>
      </VuePerfectScrollbar>
    </vs-prompt>
  </div>
</template>

<script>
import axios from '../axios.js'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import Datepicker from 'vuejs-datepicker'

export default {
    data() {
    return {
      headers: {
        'auth-token': `${localStorage.getItem('access_token')}`
      },
      image: '',
      items: [
        {
          title: 'Home',
          url: 'home',
        },
        {
          title: 'Profile',
          url: 'profile',
        },
        {
          title: 'Manage Members',
          url: 'members',
        },
        {
          title: 'Member Details',
          active: true
        }
      ],
      userChild: {
        name: '',
        firstName: '',
        lastName: ''
      },
      user: {},
      editMemberModal: false,
      settings: {
        maxScrollbarLength: 60,
        wheelSpeed: 0.30
      },
      member: {},
      interests: []
    }
  },
  methods: {
    closeModal () {},
    updateMember () {
      this.$validator.validateAll().then(result => {
        if (result) {
          const child = {
            child_id: this.$route.params.memberID,
            age: new Date(new Date() - new Date(this.userChild.child_dob)).getFullYear() - 1970,
            gender: this.gender,
            name: this.userChild.firstName + ',' + this.userChild.lastName,
            interests_id: [],
            child_dob: new Date(this.userChild.child_dob).toISOString()
          }
          const formData = new FormData()
          formData.append('child_id', this.$route.params.memberID)
          formData.append('photo', this.$refs.fileInput.files[0])
          formData.append('age', child.age)
          formData.append('name', child.name)
          formData.append('dob', child.child_dob)
          axios.post('v1/user/updateChild', formData, {headers: this.headers})
          .then((response) => {
            if (response.data.code == 0) {
              this.$vs.loading.close()
              this.$vs.notify({
                color:'danger',
                title: 'Error',
                text: response.data.message,
                position: 'top-center'
              })
            } else {
              this.loadMemberDetails()
              this.editMemberModal = false
              this.$vs.loading.close()
            }
          })
        }
      })
    },
    changeImage () {
      const [file] = this.$refs.fileInput.files
      if (file) {
        this.image = URL.createObjectURL(file)
      }
      const formData = new FormData()
      formData.append('file', this.$refs.fileInput.files[0])
    },
    editMember () {
      this.editMemberModal = true
      this.userChild.child_dob = this.member.child_dob
      this.userChild.child_photo = this.member.child_photo
      this.userChild.firstName = this.member.child_name.split(',')[0]
      this.userChild.lastName = this.member.child_name.split(',')[1]
    },
    loadInterests () {
      axios.get('v1/interestsList', {})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.interests = response.data.payload
        }
      })
    },
    loadMemberDetails () {
      this.$vs.loading()
      axios.post('v1/user/userChildDetails', {}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.member = response.data.payload.child_data.filter(member => Number(member.child_id) === Number(this.$route.params.memberID))
          this.member = this.member[0]
          this.$vs.loading.close()
        }
      })
    },
    interestDetail(interest) {
      this.$router.push({
        name: 'member-interests-details',
        params: {
          interestID: interest.interests_id,
          memberID: this.$route.params.memberID
        }
      })
    },
    deleteMember () {
      this.$vs.loading()
      axios.post('v1/user/removeChild', {child_id: this.member.child_id}, {headers: this.headers})
      .then(response => {
        if (response.data.code == 0) {
          this.$vs.loading.close()
          this.$vs.notify({
            color:'danger',
            title: 'Error',
            text: response.data.message,
            position: 'top-center'
          })
        } else {
          this.$vs.loading.close()
        }
      })
    }
  },
  components: {
    VuePerfectScrollbar,
    Datepicker
  },
  created () {
    this.loadInterests()
    this.loadMemberDetails()
  }
}
</script>

<style scoped>
.hero {
  background-image: url('../assets/images/footer.jpg');
}
.image-upload {
  right: 35%;
  top: 10%;
}
.image-upload>input {
  display: none;
}
.camera-icon {
  width: 32px !important;
  height: 32px !important;
  background-color: #00DCDC;
  border-radius: 50%;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.transbox {
  width: 40%;
  padding: 30px 20px 30px 20px;
  background-color: #000;
  opacity: 0.75;
}
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 200px;
  margin: 0;
}
.topPicks {
  color: #00DCDC;
  border-bottom: 1px solid #00DCDC;
}
.time {
  font-size: 13px;
  color: #999;
}
.bottom {
  font-size: 13px;
  margin-bottom: -0px;
}
.link {
  padding-bottom: 10px;
  float: right;
  font-size: 13px;
}
.card .footer .mt-1 a {
  visibility: hidden;
}
.card:hover .footer .mt-1 a {
  visibility: visible;
}
.image {
  width: 100%;
  height: 240px;
  display: block;
}
.clearfix:before,
.clearfix:after {
    display: table;
    content: "";
}
.clearfix:after {
    clear: both
}
.blue {
  color: #00DCDC;
}
.orange {
  color: #F89416;
}
.cardBody {
  position: relative;
  width: 100%;
}
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}
.cardBody .btn {
  color:#00DCDC;
  padding: 5px; 
  border-radius: 50%;
  background-color: #fff;
  position: absolute;
  top: 10%;
  right: 0%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  font-size: 20px;
  border: none;
  cursor: pointer;
  text-align: center;
}
.cardBody .topPicksText {
  color:#000;
  padding: 10px 15px;
  width: 200px;
  background-color: #fff;
  position: absolute;
  bottom: 0px;
  left: 0px;
  line-height: 1.75rem;
  border-top-right-radius: 100px;
  text-align: left;
}
.cardBody .topPicksText:hover {
  color:#fff;
  background-color: #00DCDC;
}
.cardBody .btn:hover {
  background-color: #999;
}
/* .vs-input-primary > .vs-input--input:focus {
  border: 1px solid rgba(5, 218, 220, 1) !important;
}
.vs-input-primary.isFocus .vs-input--label, 
.vs-input-primary .vs-input--input:focus~.icon-inputx, 
.vs-input-primary .vs-input--input:focus~.vs-placeholder-label {
  color: #00DCDC !important;
} */
.el-radio-button__orig-radio:checked+.el-radio-button__inner {
  color: #FFF;
  background-color: #F89416 !important;
  /* border-color: #409EFF; */
  box-shadow: -1px 0 0 0 #409eff;
}
</style>